import {
  Authenticator,
  useAuthenticator,
  ThemeProvider,
  Theme,
  useTheme,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Box, Button, Flex, Stack, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Navigate, redirect, useLocation, useNavigate } from "react-router-dom";

export default function Login() {
  // const { user, signOut } = useAuthenticator((context) => [
  //   context.user,
  //   context.signOut,
  // ]);
  // let query = useQuery();
  // const source = query.get("source");

  const { tokens } = useTheme();
  const theme: Theme = {
    name: "Auth Theme",
    tokens: {
      colors: {
        brand: {
          primary: {
            // "10": tokens.colors.blue["100"],
            "80": "#4299e1",
            "90": "#63B3ED",
            "100": "#4299e1",
          },
        },
      },
    },
  };

  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/";
  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  return (
    // <div>
    //   {user && source === "login" && (
    //     <Stack textAlign={"center"}>
    //       <Text mb={2} color={"blue.400"}>
    //         You are logged in as <b>{user?.username}</b>
    //       </Text>
    //       <Button
    //         fontSize={"sm"}
    //         fontWeight={600}
    //         color={"white"}
    //         bg={"blue.400"}
    //         _hover={{
    //           bg: "blue.300",
    //         }}
    //         onClick={signOut}
    //       >
    //         Sign Out
    //       </Button>
    //     </Stack>
    //   )}
    //   {user && source === "donation" && (
    //     <Navigate to="/donation" replace={true} />
    //   )}
    //   {!user && (
    <ThemeProvider theme={theme}>
      <Stack textAlign={"center"}>
        <Text mb={2} color={"blue.400"}>
          Sign In or Create a New Accont to Donate
        </Text>
      </Stack>
      <Authenticator></Authenticator>
    </ThemeProvider>
    //   )}
    // </div>
  );
}
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
