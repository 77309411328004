import * as React from "react";
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Flex,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { Logo } from "./Logo";
import Footer from "./components/Footer";
import Header from "./components/Header";
import awsExports from "./aws-exports";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import Login from "./components/Login";
import { Routes, Route } from "react-router-dom";
import ErrorPage from "./components/ErrorPage";
import Main from "./components/Main";
import Contact from "./components/Contact";
import { DonationPage } from "./components/DonationPage";
import { RequireAuth } from "./components/RequireAuth";
import WhoWeAre from "./components/WhoWeAre";
import VisionMission from "./components/VisionMission";
import HowItWorks from "./components/HowItWorks";
import LearnMore from "./components/LearnMore";
import PoliciesPage from "./components/PoliciesPage";
Amplify.configure(awsExports);

export const App = () => (
  <Authenticator.Provider>
    <ChakraProvider theme={theme}>
      <Flex height="100vh" flexDirection="column">
        <Header></Header>
        <Box
          flex="1"
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="login" element={<Login />} />
            <Route path="error" element={<ErrorPage />} />
            <Route path="contact" element={<Contact />} />
            <Route path="whoweare" element={<WhoWeAre />} />
            <Route path="visionmission" element={<VisionMission />} />
            <Route path="howitworks" element={<HowItWorks />} />
            <Route path="learnmore" element={<LearnMore />} />
            <Route path="policy" element={<PoliciesPage />} />
            <Route
              path="donation"
              element={
                <RequireAuth>
                  <DonationPage />
                </RequireAuth>
              }
            />
          </Routes>
        </Box>
        <Footer></Footer>
      </Flex>
    </ChakraProvider>
  </Authenticator.Provider>
);
