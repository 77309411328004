import {
  Box,
  Flex,
  Heading,
  Container,
  Text,
  Button,
  Stack,
  Link,
  Icon,
  useColorModeValue,
  createIcon,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
// import MyVideo from ../images/shutterstock_24430157.mp4

export default function Main() {
  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <>
      {!isMobile && (
        <>
          <video
            autoPlay
            loop
            muted
            style={{ height: "100%", width: "100%", float: "left", top: 0 }}
          >
            <source src="/shutterstock_24430157.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <Box
            pos="absolute"
            //  top={0}
            //  left={0}
            // w="100%"
            // h="100%"
            //  backgroundColor="rgba(0, 0, 0, 0.5)"
          >
            <Container maxW={"3xl"}>
              <Stack
                as={Box}
                textAlign={"center"}
                spacing={{ base: 8, md: 14 }}
                py={{ base: 6, md: 10 }}
              >
                <Heading
                  fontWeight={600}
                  fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
                  lineHeight={"110%"}
                >
                  You can Make a difference
                  <br />
                  <Text as={"span"} color={"blue.400"}>
                    Help Where the Need is Greatest
                  </Text>
                </Heading>
                <Text color={"white"}>
                  Declutter your surroundings to free up your mind, and move
                  towards a lighter and focused life
                </Text>
                <Stack
                  direction={"column"}
                  spacing={3}
                  align={"center"}
                  alignSelf={"center"}
                  position={"relative"}
                >
                  <Button
                    colorScheme={"blue"}
                    bg={"blue.400"}
                    rounded={"full"}
                    as={"a"}
                    href={"/donation"}
                    px={6}
                    _hover={{
                      bg: "blue.500",
                    }}
                  >
                    Let's Start
                  </Button>
                  <Link color={"white"} size={"sm"} href="/learnmore">
                    Learn more
                  </Link>
                </Stack>
              </Stack>
            </Container>
          </Box>
        </>
      )}
      {isMobile && (
        <Container maxW={"3xl"}>
          <Stack
            as={Box}
            textAlign={"center"}
            spacing={{ base: 8, md: 14 }}
            py={{ base: 6, md: 10 }}
          >
            <video
              autoPlay
              loop
              muted
              style={{ height: "100%", width: "100%", float: "left", top: 0 }}
            >
              <source src="/shutterstock_24430157.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Heading
              fontWeight={600}
              fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
              lineHeight={"110%"}
            >
              You can Make a difference
              <br />
              <Text as={"span"} color={"blue.400"}>
                Help Where the Need is Greatest
              </Text>
            </Heading>
            <Text color={"gray.500"}>
              Donations to us provide not only essentials and life-saving care
              but also the resources necessary to accomplish our mission —
              including personnel, materials, buildings and equipment
            </Text>
            <Stack
              direction={"column"}
              spacing={3}
              align={"center"}
              alignSelf={"center"}
              position={"relative"}
            >
              <Button
                colorScheme={"blue"}
                bg={"blue.400"}
                rounded={"full"}
                as={"a"}
                href={"/donation"}
                px={6}
                _hover={{
                  bg: "blue.500",
                }}
              >
                Let's Start
              </Button>
              <Link color={"black"} size={"sm"} href="/learnmore">
                Learn more
              </Link>
            </Stack>
          </Stack>
        </Container>
      )}
    </>
  );
}
