import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

// Replace test data with your own
const features = [
  {
    id: 0,
    title: "Hassle-Free Donations",
    text: "We understand that parting with your belongings can be challenging. With our donation item pick-up service, we make the process hassle-free and convenient. Simply identify the items you wish to donate during our decluttering session, and we'll take care of the rest, ensuring your donations reach the appropriate charitable organizations.",
  },
  {
    id: 1,
    title: "Giving Back to the Community",
    text: "By choosing Taken Care™, you're not only creating a more organized living space but also making a positive difference in your community. Your donated items will go to those in need, providing them with essential resources and support. It's a simple way to give back and contribute to the well-being of others.",
  },
  {
    id: 2,
    title: "Environmental Impact",
    text: "Donating unwanted items is an eco-friendly choice. Rather than adding to landfills, your gently used belongings can find new life with someone else. By participating in our donation item pick-up service, you're actively reducing waste and promoting sustainability.",
  },
  {
    id: 3,
    title: "Tax Benefits",
    text: "Donating items to qualified charitable organizations can provide you with tax benefits. When you choose Taken Care™ donation pick-up service, we'll provide you with the necessary documentation to support your tax deductions. It's a win-win situation – you declutter your home, help others, and potentially receive tax benefits.",
  },
  {
    id: 4,
    title: "Expert Guidance on Donation Eligibility",
    text: "Unsure which items are suitable for donation? Our experienced decluttering specialists can provide guidance on what items can be donated and connect you with appropriate local charities or organizations that accept specific items. We'll ensure that your donations align with their needs and requirements.",
  },
  {
    id: 5,
    title: "Charitable Partnerships",
    text: "Taken Care™ has established strong relationships with local charities and nonprofit organizations. Through our donation pick-up service, we can seamlessly connect your donations with these trusted partners, ensuring that your items have a meaningful impact where they are most needed.",
  },
  {
    id: 6,
    title: "Transparency and Accountability",
    text: "We believe in transparency and accountability throughout the donation process. We work closely with reputable charitable organizations to ensure that your donations are handled responsibly and reach the intended recipients. You can trust that your contributions will make a real difference.",
  },
];

export default function VisionMission() {
  return (
    <Box p={4}>
      <Stack spacing={4} as={Container} maxW={"6xl"} textAlign={"center"}>
        <Heading fontSize={"3xl"}>Whom do we serve?</Heading>
        <Text color={"gray.600"}>
          At Taken Care™, our mission is to create a clutter-free and harmonious
          living environment for individuals and families alike. We proudly
          serve a diverse range of clients, offering our expertise and
          personalized services to meet their unique needs. Whether you're a
          busy professional seeking an organized home sanctuary, a growing
          family in need of functional spaces, or a senior looking to downsize
          and simplify, we are here to transform your living space into a place
          of serenity and balance. Our team understands that each client is
          different, and we take the time to listen, understand, and tailor our
          services to your specific goals and preferences. With Taken Care™, you
          can trust that our dedicated decluttering specialists and concierge
          professionals will deliver exceptional results, creating a space that
          reflects your lifestyle and brings you peace of mind. Experience the
          transformative power of Taken Care™ and join our community of
          satisfied clients who have discovered the joy of a clutter-free and
          harmonious home.
        </Text>
        <Heading fontSize={"3xl"}>
          Discover the Difference with Taken Care™
        </Heading>
        <Text color={"gray.600"}>
          Say goodbye to the stress and chaos of clutter and embrace a more
          harmonious lifestyle with Taken Care™ Our comprehensive decluttering
          services, combined with personalized concierge solutions, will
          transform your home into an oasis of tranquility. Contact us today for
          a complimentary consultation and take the first step towards a
          clutter-free, harmonious home. Let Taken Care™ be your partner in
          creating a space that inspires, rejuvenates, and allows you to live
          your best life. In addition to the compelling features of our
          decluttering service, Taken Care™ offers a convenient and impactful
          donation item pick-up service.
        </Text>
      </Stack>

      <Container maxW={"6xl"} mt={10}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
          {features.map((feature) => (
            <HStack key={feature.id} align={"top"}>
              <Box color={"green.400"} px={2}>
                <Icon as={CheckIcon} />
              </Box>
              <VStack align={"start"}>
                <Text fontWeight={600}>{feature.title}</Text>
                <Text color={"gray.600"}>{feature.text}</Text>
              </VStack>
            </HStack>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
}
