import {
  Box,
  Heading,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

const PoliciesPage = () => {
  return (
    <Box maxWidth="800px" mx="auto" py={8} px={4}>
      <Heading as="h1" mb={4}>
        Policies
      </Heading>
      <Text>
        Protecting your privacy is important to us. We are committed to
        maintaining your confidence and trust in all of our activities with you.
      </Text>
      <Box mb={6}>
        <Heading as="h2" size="lg" mb={2}>
          Why do we collect and use your personal information?
        </Heading>
        <Text>
          We collect, use, disclose and retain personal information only for the
          purposes to serve you. We will not collect, use, disclose or retain
          personal information for any other purposes unless your consent to do
          so is obtained. We may collect personal information from you for the
          following purposes:
        </Text>
        <UnorderedList>
          <ListItem>
            To provide you with the service(s) you have requested.
          </ListItem>
          <ListItem>
            To offer you an additional service we believe you might be
            interested in;
          </ListItem>
          <ListItem>
            To conduct customer satisfaction surveys to improve and manage our
            relationship with you.
          </ListItem>
          <ListItem>
            To provide you with support and to respond to your inquiries,
            including addressing your concerns and monitoring and improve our
            level of service.
          </ListItem>
          <ListItem>
            To inform you of new promotional events and services offered by us.
          </ListItem>
          <ListItem>
            To detect security incidents and protect against malicious,
            deceptive, fraudulent or illegal activity.
          </ListItem>
          <ListItem>To provide you with service notifications; and</ListItem>
        </UnorderedList>
      </Box>

      <Box mb={6}>
        <Heading as="h2" size="lg" mb={2}>
          Why do you share my personal information?
        </Heading>
        <Text>
          We may share your personal information with our employees and business
          partners only if needed to provide services to you or for the purposes
          described in this Privacy Policy. We also may share your personal
          information with the following entities:
        </Text>
        <UnorderedList>
          <ListItem>
            Unaffiliated service providers with whom we may subcontract,
            including website hosting companies, fulfillment companies, payment
            processing companies, and other third-party service providers.
          </ListItem>
          <ListItem>
            And government agencies and law enforcement officials, when required
            to do so to respond to subpoenas, court orders or legal process, or
            to establish or exercise our legal rights or defend against legal
            claims.
          </ListItem>
        </UnorderedList>
      </Box>

      <Box mb={6}>
        <Heading as="h2" size="lg" mb={2}>
          How much information do you collect from me?
        </Heading>
        <Text>
          We strive to limit the amount of personal information. And personal
          Information will only be retained for the period of time required to
          fulfill the purpose for which we collected.
        </Text>
      </Box>

      <Box mb={6}>
        <Heading as="h2" size="lg" mb={2}>
          How do you obtain my consent?
        </Heading>
        <Text>
          We need your consent to provide you the services. We will ask for
          consent to collect, use, disclose or retain your personal information.
          The exceptions to when we will ask for consent are outlined in this
          Privacy Policy, or when we are authorized or required by law. Consent
          can be expressed, implied/inferred, or given through an authorized
          representative such as a lawyer, agent, or broker.
        </Text>
      </Box>

      <Box mb={6}>
        <Heading as="h2" size="lg" mb={2}>
          How do you safeguard my information?
        </Heading>
        <Text>
          We are committed to keeping your personal information accurate,
          complete, current, and safe. Personal Information will be protected by
          security safeguards that are appropriate to the sensitivity level of
          the information. We take all necessary precautions to protect your
          Personal Information from any loss or theft, unauthorized use, access
          or disclosure, copying or modification.
        </Text>
        <Heading as="h2" size="sm" mb={2}>
          Right to Access
        </Heading>
        <Text>
          You have a right to access your personal information held by us. Upon
          request to access your personal information, we will provide a
          response within 30 business days. We may require you to verify your
          identity to ensure that the access request is legitimate. We may
          request that you specify the type of information you would like to
          access. If we are unable to fulfill the access request within 30
          business days, a written notice of time extension will be provided
          within 30 days of the request.
        </Text>
        <Heading as="h2" size="sm" mb={2}>
          Questions or Concerns
        </Heading>
        <Text>
          If you would like to submit a request (Data Subject Access Request
          (DSAR)) related to any personal information about you held by us or if
          you have any questions, concerns or complaints, please submit a
          written request.
        </Text>
      </Box>

      <Heading as="h1" mb={4}>
        Terms of Site Use
      </Heading>
      <Box mb={6}>
        <Heading as="h2" size="lg" mb={2}>
          Site Specific Terms of Use
        </Heading>
        <Text>
          By using this Site or by submitting personal information to us through
          or in connection with this Site, you signify your consent to the
          collection, use, disclosure and retention of your personal information
          in accordance with this Privacy Policy. If you do not consent to the
          collection, use, disclosure and retention of your personal information
          in accordance with this Privacy Policy, you may not use this Site.
        </Text>
      </Box>
      <Box mb={6}>
        <Heading as="h2" size="lg" mb={2}>
          Tracking Technology
        </Heading>
        <Text>
          We collect non-personal Information about our website traffic and
          usage patterns from technology such as Cookies and Web Beacons.
          Cookies are small text files that help us to personalize the content
          of the Site.
        </Text>
      </Box>
      <Box mb={6}>
        <Heading as="h2" size="lg" mb={2}>
          Information You Specifically Provide
        </Heading>
        <Text>
          We collect Information that you voluntarily provide when you register
          to access and use certain portions of the Site. We also collect
          Information from you that is necessary to identify you and to process
          and fulfill your requests for information or services from us, or to
          provide you with franchise information. If you interact with our Site
          by booking services, submitting feedback, joining a mailing list,
          writing a review or comment, or requesting any franchise information,
          you may provide us with personal information, including but not
          limited to:
        </Text>
        <UnorderedList>
          <ListItem>First and last name</ListItem>
          <ListItem>Address</ListItem>
          <ListItem>Email address</ListItem>
          <ListItem>Phone number</ListItem>
        </UnorderedList>
        <Text>
          You may choose not to provide certain personal information, but then
          you may not be able to use certain features of the Site and we may not
          be able to provide you with certain services or information.
        </Text>
      </Box>
      <Box mb={6}>
        <Heading as="h2" size="lg" mb={2}>
          Security
        </Heading>
        <Text>
          We use a variety of standard protections to maintain the security of
          your online session. Although the Site has security measures in place
          to protect against the loss, misuse and alteration of information
          under our control, we cannot guarantee that such security measures
          will be sufficient, will not be breached, and that your information
          may not be disclosed or otherwise accessed in any unauthorized manner.
          Certain information may be transmitted to you by electronic mail.
          Although it may be illegal to intercept or disclose these messages,
          these transmissions are not secured.
        </Text>
      </Box>
      <Box mb={6}>
        <Heading as="h2" size="lg" mb={2}>
          Right to Reject
        </Heading>
        <OrderedList>
          <ListItem>
            Grounds for Rejection: Specify the grounds or reasons for which you
            may exercise this right. For example, common grounds may include:
            <UnorderedList>
              <ListItem>
                Non-compliance: State that you may refuse service if the
                individual or organization fails to comply with your terms of
                service, policies, or guidelines.
              </ListItem>
              <ListItem>
                Illegal Activities: We possess the right to reject service if
                the individual or organization engages in or promotes illegal
                activities through your platform.
              </ListItem>
              <ListItem>
                Misconduct or Abuse: We can refuse service to individuals or
                organizations that engage in abusive, disrespectful, or harmful
                behavior towards you, your employees.
              </ListItem>
              <ListItem>
                Violation of Policies: We may reject service if the individual
                or organization repeatedly violates your website's policies,
                despite warnings or corrective actions.
              </ListItem>
              <ListItem>
                Conflict of Interest: We have the discretion to reject service
                if there is a conflict of interest that could compromise the
                integrity, reputation, or ethical standards of our business.
              </ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            Notice and Communication: We will provide notice to the individual
            or organization regarding the rejection of service whenever
            possible. And communication channels may be used such as email or
            registered mail and emphasize that the decision to reject service is
            final.
          </ListItem>
          <ListItem>
            Non-Discrimination: Right to reject service will be exercised
            without discrimination based on factors such as race, color,
            religion, gender, sexual orientation, nationality, or any other
            protected characteristic, in accordance with applicable laws.
          </ListItem>
          <ListItem>
            Discretionary Nature: The decision to reject service is at your
            discretion, and you are not obligated to provide detailed
            explanations for your decision unless required by law.
          </ListItem>
          <ListItem>
            Refund or Obligations: If applicable, clarify the refund policy in
            case of rejection as a service. Specify whether any fees or payments
            made by the rejected individual or organization will be refunded or
            if any other obligations will arise as a result of the rejection.
          </ListItem>
          <ListItem>
            Refund Types: Our refund process is offered to those customers who
            are not served in given time frame. Depending on the services we may
            refund full or partial amount or credits toward future services.
          </ListItem>
          <ListItem>
            Refund Process: In case of refund for services user need to call the
            number mentioned on website or form under contact is can be used for
            the same.
          </ListItem>
        </OrderedList>
      </Box>
    </Box>
  );
};

export default PoliciesPage;
