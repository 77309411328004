import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

// Replace test data with your own
const features = [
  {
    id: 0,
    title: "Professional Expertise",
    text: "Our highly trained decluttering experts possess the skills and knowledge to efficiently assess, plan, and implement effective organization systems. They will work closely with you, considering your preferences and lifestyle, to create a customized solution that maximizes space and enhances efficiency.",
  },
  {
    id: 1,
    title: "Personalized Approach",
    text: "We understand that every home and individual is different. That is why we take the time to listen to your specific challenges and goals. Whether you need assistance decluttering a single room, an entire house, or downsizing for a move, our personalized approach ensures a transformative experience tailored to your requirements.",
  },
  {
    id: 2,
    title: "Comprehensive Services",
    text: "Beyond decluttering, Taken Care™ offers an array of concierge services to make your life easier. Need help with home staging, wardrobe organization, digital decluttering, or even managing household tasks? Our dedicated team is here to assist you, simplifying your life and saving you valuable time.",
  },
  {
    id: 3,
    title: "Confidentiality and Trust",
    text: "We understand the importance of privacy and trust when inviting someone into your home. Our decluttering specialists are thoroughly vetted, ensuring that they are reliable, professional, and respectful. You can feel confident entrusting us with your space and belongings.",
  },
  {
    id: 4,
    title: "Sustainable Practices",
    text: "We are committed to promoting environmentally friendly practices. Throughout the decluttering process, we encourage recycling, repurposing, and donating items, minimizing waste, and contributing to a greener future.",
  },
  {
    id: 5,
    title: "Transformative Results",
    text: "Experience the joy of a clutter-free home that reflects your personality and brings you peace of mind. Our goal is to create a space where you can relax, recharge, and truly enjoy your surroundings.",
  },
];

export default function WhoWeAre() {
  return (
    <Box p={4}>
      <Stack spacing={4} as={Container} maxW={"6xl"} textAlign={"center"}>
        <Heading fontSize={"3xl"}>Who We Are</Heading>
        <Text color={"gray.600"}>
          At Taken Care™, we understand that a cluttered environment not only
          affects your physical space but also your mental well-being. Our team
          of experienced decluttering specialists is here to transform your home
          into a haven of calm, balance, and functionality. We go beyond simple
          junk and items pick-up and tidying up; we provide comprehensive
          solutions tailored to your unique needs
        </Text>
        <Heading as="h4" size="md">
          Why Choose Taken Care™?
        </Heading>
      </Stack>

      <Container maxW={"6xl"} mt={10}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
          {features.map((feature) => (
            <HStack key={feature.id} align={"top"}>
              <Box color={"green.400"} px={2}>
                <Icon as={CheckIcon} />
              </Box>
              <VStack align={"start"}>
                <Text fontWeight={600}>{feature.title}</Text>
                <Text color={"gray.600"}>{feature.text}</Text>
              </VStack>
            </HStack>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
}
