import {
  Container,
  Flex,
  Box,
  Heading,
  Text,
  IconButton,
  Button,
  VStack,
  HStack,
  Wrap,
  WrapItem,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { MdPhone, MdEmail, MdLocationOn, MdMailOutline } from "react-icons/md";
import { BsPerson } from "react-icons/bs";
import { FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import ContactChakraCreateForm from "../ui-components/ContactChakraCreateForm";
import { createTheme, ThemeProvider } from "@aws-amplify/ui-react";
import { studioTheme } from "../ui-components";

const updatedTheme = createTheme(
  {
    // Extend the theme to update the button color
    name: "my-theme-updates",
    tokens: {
      components: {
        button: {
          primary: {
            backgroundColor: {
              value: "#4299e1",
            },
            _hover: {
              backgroundColor: { value: "#63B3ED" },
            },
            _focus: {
              backgroundColor: { value: "#4299e1" },
            },
            _active: {
              backgroundColor: { value: "#4299e1" },
            },
          },
        },
      },
    },
  },
  studioTheme
);

export default function Contact() {
  const toast = useToast();
  return (
    <Container maxW="full" mt={0} centerContent overflow="hidden">
      <Flex>
        <Box
          bg="gray.50"
          color="blue.400"
          borderRadius="lg"
          m={{ sm: 4, md: 16, lg: 10 }}
          p={{ sm: 5, md: 5, lg: 16 }}
        >
          <Box p={4}>
            <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}>
              <WrapItem>
                <Box>
                  <Heading>Contact</Heading>
                  <Text mt={{ sm: 3, md: 3, lg: 5 }} color="gray.600">
                    Fill up the form below to contact
                  </Text>
                  <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                    <VStack pl={0} spacing={3} alignItems="flex-start">
                      <Button
                        size="md"
                        height="48px"
                        width="250px"
                        variant="ghost"
                        color="blue.400"
                        as={"a"}
                        href="mailto:takencarellc@gmail.com"
                        _hover={{ border: "2px solid #4299e1" }}
                        leftIcon={<MdEmail color="#4299e1" size="20px" />}
                      >
                        takencarellc@gmail.com
                      </Button>
                    </VStack>
                  </Box>
                  <HStack
                    mt={{ lg: 10, md: 10 }}
                    spacing={5}
                    px={5}
                    alignItems="flex-start"
                  >
                    <IconButton
                      aria-label="twitter"
                      variant="ghost"
                      size="lg"
                      isRound={true}
                      _hover={{ bg: "blue.200" }}
                      icon={<FaTwitter size="28px" />}
                    />
                    <IconButton
                      aria-label="youtube"
                      variant="ghost"
                      size="lg"
                      isRound={true}
                      _hover={{ bg: "blue.200" }}
                      icon={<FaYoutube size="28px" />}
                    />
                    <IconButton
                      aria-label="instagram"
                      variant="ghost"
                      size="lg"
                      isRound={true}
                      _hover={{ bg: "blue.200" }}
                      icon={<FaInstagram size="28px" />}
                    />
                  </HStack>
                </Box>
              </WrapItem>
              <WrapItem>
                <Box bg="white" borderRadius="lg">
                  {/* <Box m={8} color="#0B0E3F">
                      <VStack spacing={5}>
                        <FormControl id="name">
                          <FormLabel>Name</FormLabel>
                          <InputGroup borderColor="#E0E1E7">
                            <InputLeftElement
                              pointerEvents="none"
                              children={<BsPerson color="gray.800" />}
                            />
                            <Input type="text" size="md" />
                          </InputGroup>
                        </FormControl>
                        <FormControl id="name">
                          <FormLabel>Email</FormLabel>
                          <InputGroup borderColor="#E0E1E7">
                            <InputLeftElement
                              pointerEvents="none"
                              children={<MdMailOutline color="gray.800" />}
                            />
                            <Input type="text" size="md" />
                          </InputGroup>
                        </FormControl>
                        <FormControl id="name">
                          <FormLabel>Message</FormLabel>
                          <Textarea
                            borderColor="gray.300"
                            _hover={{
                              borderRadius: 'gray.300',
                            }}
                            placeholder="Type your message here"
                          />
                        </FormControl>
                        <FormControl id="name" float="right">
                          <Button
                            variant="solid"
                            bg="blue.400"
                            color="white"
                            _hover={{}}>
                            Send Message
                          </Button>
                        </FormControl>
                      </VStack>
                    </Box> */}
                  <ThemeProvider theme={updatedTheme}>
                    <ContactChakraCreateForm
                      onSuccess={() =>
                        toast({
                          position: "top",
                          title: "Message Received.",
                          description:
                            "Thanks for submitting. Our team will get back to you soon.",
                          duration: 5000,
                          isClosable: true,
                        })
                      }
                    />
                  </ThemeProvider>
                </Box>
              </WrapItem>
            </Wrap>
          </Box>
        </Box>
      </Flex>
    </Container>
  );
}
