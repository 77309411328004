import { useAuthenticator } from "@aws-amplify/ui-react";
import { AtSignIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  FormControl,
  FormLabel,
  Input,
  Flex,
  InputGroup,
  InputLeftElement,
  Text,
  Select,
  useToast,
  Stack,
  Heading,
} from "@chakra-ui/react";
import React from "react";
import { DonationStatus } from "../models";
import DonationChakraCreateForm from "../ui-components/DonationChakraCreateForm";
import { createTheme, ThemeProvider } from "@aws-amplify/ui-react";
import { studioTheme } from "../ui-components";
import DonationTable from "./DonationTable";
import { Navigate } from "react-router-dom";

const updatedTheme = createTheme(
  {
    // Extend the theme to update the button color
    name: "my-theme-updates",
    tokens: {
      components: {
        button: {
          primary: {
            backgroundColor: {
              value: "#4299e1",
            },
            _hover: {
              backgroundColor: { value: "#63B3ED" },
            },
            _focus: {
              backgroundColor: { value: "#4299e1" },
            },
            _active: {
              backgroundColor: { value: "#4299e1" },
            },
          },
        },
      },
    },
  },
  studioTheme
);

export function DonationPage() {
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const { user } = useAuthenticator((context) => [context.user]);
  const initialRef = React.useRef(null);
  const toast = useToast();

  // if(!user) {
  //   return <Navigate to="/login?source=donation" replace={true} />;
  // }
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select a Service</ModalHeader>
          <ModalCloseButton />
          {/* <ModalBody>
            <Flex flexWrap="wrap" justifyContent="space-between">
              <Box width={{ base: "100%", md: "49%" }}>
                <FormControl mt={2}>
                  <Input ref={initialRef} placeholder="First name" size="md" />
                </FormControl>
                <FormControl mt={2}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<AtSignIcon color="gray.300" />}
                    />
                    <Input type="tel" placeholder="Email" />
                  </InputGroup>
                </FormControl>
              </Box>
              <Box width={{ base: "100%", md: "49%" }}>
                <FormControl mt={2}>
                  <Input placeholder="Last name" />
                </FormControl>
                <FormControl mt={2}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<PhoneIcon color="gray.300" />}
                    />
                    <Input type="tel" placeholder="Mobile" />
                  </InputGroup>
                </FormControl>
              </Box>
              <FormControl mt={2}>
                <Input placeholder="Address" />
              </FormControl>
              <Box width={{ base: "100%", md: "49%" }}>
                <FormControl mt={2}>
                  <Input placeholder="City" />
                </FormControl>
                <FormControl mt={2}>
                  <Input placeholder="Zip Code" />
                </FormControl>
              </Box>
              <Box width={{ base: "100%", md: "49%" }}>
                <FormControl mt={2}>
                  <Input placeholder="State" />
                </FormControl>
                <FormControl mt={2}>
                <Select placeholder="Select Donation type">
                  <option value="option1">Toys ($10 per box)</option>
                  <option value="option2">Clothes ($5 per box)</option>
                  <option value="option3">Books ($5 per box)</option>
                  <option value="option3">Other</option>
                </Select>
                </FormControl>
              </Box>
              <FormControl mt={2}>
                <Textarea
                  placeholder="Donation Item(s) Description"
                  size="sm"
                />
              </FormControl>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue">Submit</Button>
          </ModalFooter> */}
          <ThemeProvider theme={updatedTheme}>
            <DonationChakraCreateForm
              overrides={{
                email: {
                  placeholder: user?.attributes?.email,
                },
              }}
              onSuccess={() => {
                onClose();
                toast({
                  position: "top",
                  title: "Donation Received.",
                  description:
                    "Thanks for donating. Our team will get back to you soon.",
                  duration: 5000,
                  isClosable: true,
                });
              }}
              onSubmit={(fields) => {
                (fields as any).username = user?.username;
                (fields as any).status = DonationStatus.NEW;
                (fields as any).useremail = user?.attributes?.email;
                return fields;
              }}
            />
          </ThemeProvider>
        </ModalContent>
      </Modal>
      {user && user.username && user.username !== "admin" && (
        <Stack width={"100%"} textAlign={"center"}>
          <Heading mb={2} as="h4" size="md" color={"blue.400"}>
            Donation History
          </Heading>
          <DonationTable
            criteria={(p) => p.username.eq(user.username!)}
            isAdmin={false}
          ></DonationTable>
        </Stack>
      )}
      {user && user.username && user.username === "admin" && (
        <Stack width={"100%"} textAlign={"center"}>
          <Heading mb={2} mt={2} as="h4" size="md" color={"blue.400"}>
            New Donations
          </Heading>
          <DonationTable
            criteria={(p) => p.status.eq("NEW")}
            isAdmin={true}
          ></DonationTable>
          <Heading mb={2} mt={2} as="h4" size="md" color={"blue.400"}>
            In-progress Donations
          </Heading>
          <DonationTable
            criteria={(p) => p.status.eq("IN_PROGRESS")}
            isAdmin={true}
          ></DonationTable>
          <Heading mb={2} mt={2} as="h4" size="md" color={"blue.400"}>
            Completed Donations
          </Heading>
          <DonationTable
            criteria={(p) => p.status.eq("COMPLETED")}
            isAdmin={true}
          ></DonationTable>
          <Heading mb={2} mt={2} as="h4" size="md" color={"blue.400"}>
            Cancelled Donations
          </Heading>
          <DonationTable
            criteria={(p) => p.status.eq("CANCELLED")}
            isAdmin={true}
          ></DonationTable>
        </Stack>
      )}
    </>
  );
}
