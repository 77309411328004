import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

export default function LearnMore() {
  return (
    <Box p={4}>
      <Stack spacing={4} as={Container} maxW={"6xl"} textAlign={"center"}>
        <Text color={"gray.600"}>
          The art of decluttering is a hard one to master. We are so busy that
          many of our promises are lost in procrastination. We have limited time
          and resources, and we strive to make the best of it. But frequently we
          overwhelm ourselves thinking of trivialities that drain our time and
          attention. Are you overwhelmed by the chaos and clutter in your home?
          Do you find it difficult to create a serene living space amidst the
          chaos of everyday life? It's time to reclaim your sanctuary with Taken
          Care™ the premier decluttering service that combines expert
          organization with personalized concierge services.
        </Text>
        <Heading fontSize={"3xl"}>
          Experience the Simplicity of Taken Care™
        </Heading>
        <Text color={"gray.600"}>
          Discover the simplicity and transformative power of Taken Care™. Let
          our experienced decluttering specialists and concierge professionals
          guide you through the process of creating a clutter-free and
          harmonious living space. From the initial consultation to the final
          organization, our seamless and personalized approach ensures that
          every step is tailored to your unique needs and preferences.
          Experience the joy of a simplified home and a more balanced lifestyle
          with Taken Care™
        </Text>
      </Stack>
    </Box>
  );
}
