/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SelectField,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { DonationChakra } from "../models";
import { fetchByPath, validateField } from "./utils";
import { DataStore } from "aws-amplify";
export default function DonationChakraCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    address: "",
    donation_type: "",
    item_desc: "",
  };
  const [firstname, setFirstname] = React.useState(initialValues.firstname);
  const [lastname, setLastname] = React.useState(initialValues.lastname);
  const [email, setEmail] = React.useState(initialValues.email);
  const [mobile, setMobile] = React.useState(initialValues.mobile);
  const [address, setAddress] = React.useState(initialValues.address);
  const [donation_type, setDonation_type] = React.useState(
    initialValues.donation_type
  );
  const [item_desc, setItem_desc] = React.useState(initialValues.item_desc);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setFirstname(initialValues.firstname);
    setLastname(initialValues.lastname);
    setEmail(initialValues.email);
    setMobile(initialValues.mobile);
    setAddress(initialValues.address);
    setDonation_type(initialValues.donation_type);
    setItem_desc(initialValues.item_desc);
    setErrors({});
  };
  const validations = {
    firstname: [{ type: "Required" }],
    lastname: [{ type: "Required" }],
    email: [{ type: "Required" }, { type: "Email" }],
    mobile: [{ type: "Required" }],
    address: [{ type: "Required" }],
    donation_type: [],
    item_desc: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          firstname,
          lastname,
          email,
          mobile,
          address,
          donation_type,
          item_desc,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value.trim() === "") {
              modelFields[key] = undefined;
            }
          });
          await DataStore.save(new DonationChakra(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "DonationChakraCreateForm")}
      {...rest}
    >
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid0")}
      >
        <TextField
          label="First Name"
          isRequired={true}
          isReadOnly={false}
          value={firstname}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                firstname: value,
                lastname,
                email,
                mobile,
                address,
                donation_type,
                item_desc,
              };
              const result = onChange(modelFields);
              value = result?.firstname ?? value;
            }
            if (errors.firstname?.hasError) {
              runValidationTasks("firstname", value);
            }
            setFirstname(value);
          }}
          onBlur={() => runValidationTasks("firstname", firstname)}
          errorMessage={errors.firstname?.errorMessage}
          hasError={errors.firstname?.hasError}
          {...getOverrideProps(overrides, "firstname")}
        ></TextField>
        <TextField
          label="Last Name"
          isRequired={true}
          isReadOnly={false}
          value={lastname}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                firstname,
                lastname: value,
                email,
                mobile,
                address,
                donation_type,
                item_desc,
              };
              const result = onChange(modelFields);
              value = result?.lastname ?? value;
            }
            if (errors.lastname?.hasError) {
              runValidationTasks("lastname", value);
            }
            setLastname(value);
          }}
          onBlur={() => runValidationTasks("lastname", lastname)}
          errorMessage={errors.lastname?.errorMessage}
          hasError={errors.lastname?.hasError}
          {...getOverrideProps(overrides, "lastname")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid1")}
      >
        <TextField
          label="Email"
          isRequired={true}
          isReadOnly={false}
          value={email}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                firstname,
                lastname,
                email: value,
                mobile,
                address,
                donation_type,
                item_desc,
              };
              const result = onChange(modelFields);
              value = result?.email ?? value;
            }
            if (errors.email?.hasError) {
              runValidationTasks("email", value);
            }
            setEmail(value);
          }}
          onBlur={() => runValidationTasks("email", email)}
          errorMessage={errors.email?.errorMessage}
          hasError={errors.email?.hasError}
          {...getOverrideProps(overrides, "email")}
        ></TextField>
        <TextField
          label="Mobile"
          isRequired={true}
          isReadOnly={false}
          value={mobile}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                firstname,
                lastname,
                email,
                mobile: value,
                address,
                donation_type,
                item_desc,
              };
              const result = onChange(modelFields);
              value = result?.mobile ?? value;
            }
            if (errors.mobile?.hasError) {
              runValidationTasks("mobile", value);
            }
            setMobile(value);
          }}
          onBlur={() => runValidationTasks("mobile", mobile)}
          errorMessage={errors.mobile?.errorMessage}
          hasError={errors.mobile?.hasError}
          {...getOverrideProps(overrides, "mobile")}
        ></TextField>
      </Grid>
      <TextField
        label="Address"
        isRequired={true}
        isReadOnly={false}
        value={address}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              email,
              mobile,
              address: value,
              donation_type,
              item_desc,
            };
            const result = onChange(modelFields);
            value = result?.address ?? value;
          }
          if (errors.address?.hasError) {
            runValidationTasks("address", value);
          }
          setAddress(value);
        }}
        onBlur={() => runValidationTasks("address", address)}
        errorMessage={errors.address?.errorMessage}
        hasError={errors.address?.hasError}
        {...getOverrideProps(overrides, "address")}
      ></TextField>
      <SelectField
        label="Donation Type"
        placeholder="Please select an option"
        isDisabled={false}
        value={donation_type}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              email,
              mobile,
              address,
              donation_type: value,
              item_desc,
            };
            const result = onChange(modelFields);
            value = result?.donation_type ?? value;
          }
          if (errors.donation_type?.hasError) {
            runValidationTasks("donation_type", value);
          }
          setDonation_type(value);
        }}
        onBlur={() => runValidationTasks("donation_type", donation_type)}
        errorMessage={errors.donation_type?.errorMessage}
        hasError={errors.donation_type?.hasError}
        {...getOverrideProps(overrides, "donation_type")}
      >
        <option
          children="Books ($5 per bag)"
          value="Books ($5 per bag)"
          {...getOverrideProps(overrides, "donation_typeoption0")}
        ></option>
        <option
          children="Toys ($10 per bag)"
          value="Toys ($10 per bag)"
          {...getOverrideProps(overrides, "donation_typeoption1")}
        ></option>
        <option
          children="Clothes ($10 per bag)"
          value="Clothes ($10 per bag)"
          {...getOverrideProps(overrides, "donation_typeoption2")}
        ></option>
        <option
          children="Kitchen Utensils ($10 per bag)"
          value="Kitchen Utensils ($10 per bag)"
          {...getOverrideProps(overrides, "donation_typeoption3")}
        ></option>
        <option
          children="Other"
          value="Other"
          {...getOverrideProps(overrides, "donation_typeoption4")}
        ></option>
      </SelectField>
      <TextAreaField
        label="Donation Description"
        isRequired={false}
        isReadOnly={false}
        placeholder="Add details about your donation here (Condition, Number of bags or boxes etc.)"
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              firstname,
              lastname,
              email,
              mobile,
              address,
              donation_type,
              item_desc: value,
            };
            const result = onChange(modelFields);
            value = result?.item_desc ?? value;
          }
          if (errors.item_desc?.hasError) {
            runValidationTasks("item_desc", value);
          }
          setItem_desc(value);
        }}
        onBlur={() => runValidationTasks("item_desc", item_desc)}
        errorMessage={errors.item_desc?.errorMessage}
        hasError={errors.item_desc?.hasError}
        {...getOverrideProps(overrides, "item_desc")}
      ></TextAreaField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
