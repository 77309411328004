import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

// Replace test data with your own
const features = [
  {
    id: 0,
    title: "Customized Plan",
    text: " Based on the information gathered during the consultation, our experienced decluttering specialists will develop a customized plan that outlines the recommended steps, timeline, and strategies to achieve your desired results. We'll consider your preferences, lifestyle, and any specific areas of focus you may have.",
  },
  {
    id: 1,
    title: "Decluttering Session",
    text: "Once the plan is finalized, our dedicated team will work with you to schedule a decluttering session. During this session, our experts will guide you through the process, helping you sort, categorize, and make decisions about the items in your home. We'll assist you in identifying items to keep, donate, or discard, ensuring that your space becomes organized and functional.",
  },
  {
    id: 2,
    title: "Donation Item Pick-up",
    text: "If you choose our donation item pick-up service, we'll coordinate the logistics of collecting and delivering your donations to local charities or organizations. This ensures that your items go to those in need, providing a meaningful impact to the community.",
  },
  {
    id: 3,
    title: "Organization and Styling",
    text: "Once the decluttering phase is complete, our team will focus on organizing your belongings in a way that maximizes space, enhances efficiency, and suits your lifestyle. We'll implement customized storage solutions, labeling systems, and organization strategies that not only maintain order but also reflect your personal taste and aesthetic preferences.",
  },
  {
    id: 4,
    title: "Concierge Services",
    text: "In addition to decluttering and organization, Taken Care™ offers a range of concierge services to simplify your life. Whether it's home staging, wardrobe organization, digital decluttering, or managing household tasks, our team is here to assist you with any additional services you may require.",
  },
  {
    id: 5,
    title: "Ongoing Support",
    text: "We believe in building long-lasting relationships with our clients. After the initial decluttering and organization process, we offer ongoing support and maintenance plans to help you sustain a clutter-free environment. Our team can provide regular check-ins, follow-up sessions, or seasonal refreshes to ensure that your space remains harmonious and clutter-free.",
  },
];

export default function HowItWorks() {
  return (
    <Box p={4}>
      <Stack spacing={4} as={Container} maxW={"6xl"} textAlign={"center"}>
        <Heading fontSize={"3xl"}>
          Discover the Simplicity of Taken Care™: How It Works
        </Heading>
        <Text color={"gray.600"}>
          At Taken Care™, we've designed our decluttering and concierge services
          to be seamless, stress-free, and tailored to your unique needs. Our
          goal is to simplify the process of transforming your living space into
          a clutter-free haven. Here's a step-by-step guide on how it works:
          Initial Consultation: We begin by scheduling an initial consultation,
          either in person or virtually, to understand your decluttering goals,
          challenges, and aspirations for your home. This allows us to get a
          clear picture of your needs and enables us to provide you with the
          best possible solutions.
        </Text>
      </Stack>

      <Container maxW={"6xl"} mt={10}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
          {features.map((feature) => (
            <HStack key={feature.id} align={"top"}>
              <Box color={"green.400"} px={2}>
                <Icon as={CheckIcon} />
              </Box>
              <VStack align={"start"}>
                <Text fontWeight={600}>{feature.title}</Text>
                <Text color={"gray.600"}>{feature.text}</Text>
              </VStack>
            </HStack>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
}
