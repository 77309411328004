// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const DonationStatus = {
  "NEW": "NEW",
  "IN_PROGRESS": "IN_PROGRESS",
  "COMPLETED": "COMPLETED",
  "CANCELLED": "CANCELLED"
};

const { DonationChakra, ContactChakra } = initSchema(schema);

export {
  DonationChakra,
  ContactChakra,
  DonationStatus
};