import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DonationChakra, DonationStatus } from "../models";
import { DataStore } from "aws-amplify";
import {
  ObserveQueryOptions,
  RecursiveModelPredicateExtender,
  SortDirection,
} from "@aws-amplify/datastore";
import { PredicateAll } from "@aws-amplify/datastore/lib-esm/predicates";
import { createMuiTheme, createTheme, ThemeProvider } from "@mui/material";

const columns: GridColDef[] = [
  {
    field: "status",
    headerName: "Status",
    width: 150,
    editable: true,
    type: "singleSelect",
    valueOptions: ["NEW", "IN_PROGRESS", "COMPLETED", "CANCELLED"],
  },
  {
    field: "firstname",
    headerName: "First name",
    width: 150,
  },
  {
    field: "lastname",
    headerName: "Last name",
    width: 150,
  },
  {
    field: "email",
    headerName: "Email",
    width: 180,
  },
  {
    field: "mobile",
    headerName: "Mobile",
    width: 110,
  },
  {
    field: "address",
    headerName: "Address",
    width: 200,
  },
  {
    field: "item_desc",
    headerName: "Item Description",
    width: 200,
  },
  {
    field: "donation_type",
    headerName: "Type",
    width: 180,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    width: 180,
  },
  { field: "username", headerName: "Submitted By", width: 120 },
  { field: "useremail", headerName: "Submitter Email", width: 120 },
];

export declare type DonationTableProps = {
  criteria?:
    | RecursiveModelPredicateExtender<DonationChakra>
    | typeof PredicateAll;
  paginationProducer?: ObserveQueryOptions<DonationChakra>;
  isAdmin: boolean;
};

const updateRowStatus = async (id: any, newStatus: DonationStatus) => {
  const original = await DataStore.query(DonationChakra, id);
  if (original) {
    await DataStore.save(
      DonationChakra.copyOf(original, (updated) => {
        updated.status = newStatus;
      })
    );
  }
};

export default function DonationTable(props: DonationTableProps) {
  const [rows, setRows] = React.useState<DonationChakra[]>([]);
  const muiTheme = createTheme();

  React.useEffect(() => {
    const subscription = DataStore.observeQuery(
      DonationChakra,
      props.criteria,
      {
        sort: (d) => d.createdAt(SortDirection.DESCENDING),
      }
    ).subscribe((snapshot) => {
      const { items, isSynced } = snapshot;
      console.log(
        `[Snapshot] item count: ${items.length}, isSynced: ${isSynced}`
      );
      setRows(items);
    });

    return () => {
      subscription.unsubscribe();
    };
    // .then((rows) => setRows(rows));
  }, []);

  return (
    <ThemeProvider theme={muiTheme}>
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
            columns: {
              columnVisibilityModel: {
                // Hide columns
                username: props.isAdmin,
                useremail: props.isAdmin,
              },
            },
          }}
          pageSizeOptions={[5, 10, 25]}
          checkboxSelection={false}
          isCellEditable={(params) =>
            params.colDef.field === "status" && props.isAdmin
          }
          processRowUpdate={(newRow: any, oldRow: any) => {
            console.log(oldRow);
            console.log(newRow);
            updateRowStatus(newRow.id, newRow.status);
          }}
          onProcessRowUpdateError={(error) => {
            console.log(error);
          }}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#EBF8FF",
              color: "#3182CE",
            },
          }}
        />
      </Box>
    </ThemeProvider>
  );
}
